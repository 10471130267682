<template>
    <div class="message-box">
        <div class="menu-box">
            <div class="menu-title">
                <span class="icon icon-information"></span>
                <span>站内通知</span>
            </div>
            <div 
                v-for="(item, index) in unread_number" :key="index"
                :class="['menu-item', {'active': item.type == checkedIndex}]"
                @click="change(item.type)"
                >
                <span>{{item.name}}</span>
                <i v-if="item.number > 0">{{item.number}}</i>
            </div>
        </div>
        <div class="content">
            <div class="title-box">
                <span class="title">{{(unread_number.length && unread_number[unread_number.findIndex(item => item.type == checkedIndex)].name) || '全部通知'}}</span>
                <div class="clear" @click="cleanMsg">
                    <span class="icon-edit"></span>
                    <span>清除未读</span>
                </div>
                <el-select 
                    class="select" 
                    v-model="is_read" 
                    size="large"
                    @change="isReadChange">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                </el-select>
            </div>
            <template v-if="(list.length > 0 && !errorShow)">
                <div class="message-list">
                    <template v-for="item, index in list" :key="index">
                        <div class="title-hide" :ref="`hideTit${index}`">{{item.title}}</div>
                        <div class="message-item">
                            <div 
                                :class="['message-title', {'message-title-unread': item.is_read == 0}, {'message-title-reading': index == messageIndex}]"
                                @click="checkMessage(item, index)">
                                <i class="icon"></i>
                                <span class="title" :ref="`Tit${index}`">{{item.title}}</span>
                                <span class="time">{{$dayjs(item.created_time).format('YYYY-MM-DD HH:mm')}}</span>
                                <div class="arrow">
                                    <span class="icon-arrowdown"></span>
                                </div>
                            </div>
                            <el-collapse-transition>
                                <div v-show="messageIndex == index">
                                    <div class="message-content">
                                        <div v-html="item.content"></div>
                                        <div v-if="item.attachment && item.attachment.length" class="attachment">
                                            <span>附件：</span>
                                            <template v-for="sitem, sindex in item.attachment" :key="sindex">
                                                <a :href="sitem.url">{{sitem.name}}</a>
                                                <span v-if="sindex < item.attachment.length - 1">，</span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-transition>
                        </div>
                    </template>
                </div>
                <CompPagination 
                    :total="total"
                    :pageSize="page_size"
                    @change="pageChange"
                    />
            </template>
            <div v-else class="error-box">
                <img src="https://p6.moimg.net/mdwxapp/empty_message_icon.png" />
                <div>暂无消息</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { getCpMessageList, readPcMessage, cleanPcMessageCount } from "@/api/index"
import CompPagination from "@@/common/pagination.vue"
export default {
    components: {
        CompPagination
    },
    computed: {
        ...mapState({
            userDetail: state => state.user.userDetail,
            userCpTag: state => state.user.userCpTag

        })
    },
    data() {
        return {
            unread_number: [],
            checkedIndex: -1,
            options: [{
                label: '全部状态',
                value: -1
            },{
                label: '已读',
                value: 1
            },{
                label: '未读',
                value: 0
            }],
            is_read: -1,
            list: [],
            messageIndex: null,
            total: 0,
            page: 1,
            page_size: 10,
            errorShow: false,
            cleanLoading: false
        }
    },
    watch: {
       
    },
    mounted(){
        this.getList()
    }, 
    methods: {
        ...mapMutations(['currentMessage']),
        async getList() {
            const { status, data } = await getCpMessageList({
                is_read: this.is_read,
                type: this.checkedIndex,
                page: this.page,
                page_size: this.page_size
            })
            if (status == 0) {
                this.errorShow = false
                this.total = data.total
                this.unread_number = data.unread_number
                this.list = data.list

                this.currentMessage(data.unread_number[0].number)
            } else {
                this.errorShow = true
            }
        },
        change(index) {
            this.checkedIndex = index
            this.page = 1
            this.getList()
        },
        // 查看详情
        checkMessage(item, index) {
            if (this.messageIndex == index) {
                this.messageIndex = null
                this.$refs[`Tit${index}`][0].style.height =  '28px'
                return
            }

            const height = this.$refs[`hideTit${index}`][0].offsetHeight
            this.$refs[`Tit${index}`][0].style.height = height + 'px'

            this.messageIndex = index
            if (item.is_read == 0) {
                this.readMessage(item.id, index)
            }
        },
        async readMessage(id, index) {
            const { status, data } = await readPcMessage({
                message_id: id
            })
            if(status == 0 && data && data.is_read_success) {
                this.list[index].is_read = 1
                this.unread_number = data.unread_number
                this.currentMessage(data.unread_number[0].number)
            }
        },
        pageChange(val) {
            this.page = val
            this.getList()
        },
        isReadChange() {
            this.page = 1
            this.getList()
        },
        // 消除PC站内信未读消息
        async cleanMsg() {
            if (this.cleanLoading) return
            if(this.total > 0) {
                this.cleanLoading = true
                await cleanPcMessageCount({
                    msg_type: this.checkedIndex == -1 ? 100 : this.checkedIndex
                })
                this.cleanLoading = false
                this.page = 1
                this.getList()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.message-box {
    width: 1080px;
    margin: 40px auto;
    display: flex;
    line-height: 1;
    .menu-box {
        width: 240px;
        padding: 27px 10px 10px;
        background-color: #F8F8F8;
        border-radius: 5px;
        margin-right: 40px;
        .menu-title {
            font-size: 18px;
            position: relative;
            padding-left: 47px;
            font-weight: bold;
            margin-bottom: 20px;
            .icon {
                position: absolute;
                left: 20px;
            }
        }
        .menu-item {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px 0 47px;
            width: 220px;
            height: 40px;
            border-radius: 3px;
            cursor: pointer;
            &:hover, &.active {
                background: #E8E8E8;
                font-weight: bold;
            }
            i {
                padding: 0 6px;
                line-height: 16px;
                font-style: normal;
                font-size: 12px;
                color: #fff;
                background-color: #FF3535;
                border-radius: 8px;
            }
        }
    }
    .content {
        flex: 1;
        .title-box {
            border-bottom: 1px solid #E8E8E8;
            display: flex;
            justify-content: space-between;
            .title {
                font-size: 24px;
                font-weight: bold;
                padding-bottom: 19px;
            }
            .select {
                margin-top: -9px;
            }
            .clear {
                display: flex;
                align-items: center;
                margin: 0 20px 0 auto;
                line-height: 24px;
                padding-bottom: 19px;
                cursor: pointer;
                color: #7F7F7F;
                font-size: 16px;
            }
        }
        .message-list {
            min-height: calc(100vh - 416px);
            padding-top: 2px;
            margin-bottom: 20px;
            position: relative;
            .title-hide {
                width: 573px;
                font-size: 16px;
                font-weight: bold;
                color: #B1B1B1;
                z-index: -1;
                line-height: 28px;
                position: absolute;
            }
            .message-item {
                padding: 14px 0;
                border-bottom: 1px solid #E8E8E8;
                background-color: #fff;
                .message-title {
                    display: flex;
                    align-items: flex-start;
                    line-height: 28px;
                    font-size: 16px;
                    cursor: pointer;
                    .icon {
                        width: 10px;
                        height: 10px;
                        background-color: #E8E8E8;
                        border-radius: 50%;
                        margin-right: 10px;
                        margin-top: 9px;
                    }
                    .title {
                        width: 0;
                        flex: 1;
                        font-weight: bold;
                        white-space: nowrap;
                        color: #B1B1B1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 28px;
                        transition: height .5s;
                    }
                    .time {
                        color: #7F7F7F;
                        margin-left: 20px;
                    }
                    .arrow {
                        width: 28px;
                        height: 28px;
                        background: #FFFFFF;
                        border-radius: 4px 4px 4px 4px;
                        border: 1px solid #B1B1B1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #7F7F7F;
                        margin-left: 22px;
                    }
                    &-unread {
                        .icon {
                            background-color: #FF3535;
                        }
                        .title {
                            color: #1A1A1A;
                        }
                    }
                    &-reading {
                        .title {
                            white-space: pre-wrap;
                        }
                        .arrow {
                            transform: rotateZ(180deg);
                        }
                    }
                }
                .message-content {
                    padding: 10px 0 3px 20px;
                    line-height: 24px;
                    .attachment {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .error-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #9E9E9E;
        line-height: 1;
        img {
            width: 130px;
            height: 130px;
            margin-bottom: 10px;
        }
    }
}
</style>

<style lang="scss">
.message-content {
    a {
        color: #425EB2;
        text-decoration: underline;
    }
}
</style>
